@import '../../../../styles/colors.scss';

.modalBody {
  margin: 20px;
}

.modalTextContainer {
  margin-top: 30px;
}

.modalButtonsContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: row-reverse;
}

.modalButtonsContainer>* {
  margin-right: 2px;
}

.gridContainer {
  overflow-y: auto;
  //height: 400px;
}

.detailsContainer {
  padding: 20px;
}

.borderedBlock {
  border: 2px solid $gy-blue-color;
}

.detailsBlock {
  padding: 16px;
  margin: 8px 0;
}

.textFieldContainer>* {
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.axleContainer>* {
  padding: 4px;
  margin-bottom: 10px;
}

.axleDetailsContainer>* {
  padding: 5px;
  margin-left: 10px;
}

.buttonsWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  &>* {
    margin-right: 15px;
  }
}

.bottomRow {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.bottomRow>*:not(:last-child) {
  margin-left: 5px;
  margin-right: 5px;
}

.grid {
  & > div:not(:first-of-type) {
      margin-top: 8px;
  }
}

.highlight {
  color: $gy-dark-blue-color !important;
  margin-left: 5px;
  font-weight: bold;
}

.upload {
  display: none;
}

.imageButtonsWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.imageContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.customButton {
  outline: transparent;
  position: relative;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  box-sizing: border-box;
  border: 1px solid rgb(0, 120, 212) !important;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  margin-right: 10px !important;
  padding: 10px 16px;
  border-radius: 2px;
  min-width: 80px;
  height: 32px;
  background-color: rgb(0, 120, 212) !important;
  color: rgb(255, 255, 255) !important;
  user-select: none;

  & > span {
      display: flex;
      height: 100%;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;

      & > span {
          display: block;
          flex-grow: 1;
          margin: 0px 4px;
          line-height: 100%;
          font-weight: 600;
      }
  }
}