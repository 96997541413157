.grid {
    & > div:not(:first-of-type) {
        margin-top: 8px;
    }
}

.inputGroup {
    display: flex;
    
    & > div:not(:last-of-type) {
        margin-right: 8px;
    }
}

.inputWithButton {    
    align-items: flex-end;

    & > div:first-of-type {
        width: -webkit-fill-available;
    }
}

.bigCheckbox {
    padding-bottom: 5px;

    & div {
        height: 32px;
        width: 32px;
        font-size: 24px;
    }
}

.buttonsGroup {

    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    
    & > * {
        margin-right: 8px;
    }
}
